@import url('https://fonts.googleapis.com/css2?family=Inder&family=Inter:wght@300;400;500;600;700&family=Gabarito:wght@300;400;500;700&family=Ubuntu:wght@300;400;500;700&display=swap');
/* font-family: 'Gabarito', sans-serif; */
/* font-family: 'Inter', sans-serif; */
/* font-family: 'Gabarito', sans-serif; */
/* font-family: 'Ubuntu', sans-serif; */

:root {
  --white: #ffffff;
  --white-smoke: #f1f1f1;
  --black: #000000;
  --blue: #18394f;
  --blue-secondary: #001a56;
  --blue-ordinary: #0a083b;
  --purple: #57586e;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-family: 'Gabarito', sans-serif;
  background-color: var(--white-smoke);
}
a {
  text-decoration: none !important;
}
/* input, */
/* button, */
a {
  box-shadow: none !important;
  outline: none !important;
}
/* button {
  border: 0 !important;
  outline: 0 !important;
} */

@media only screen and (max-width: 1100px) {
  body {
    font-size: 0.75em;
  }
}
@media only screen and (max-width: 768px) {
  body {
    font-size: 0.65em;
  }
}
.labelHold .ant-form-item-label {
  top: -10px !important;
  background: #fff;
}
.labelHold .ant-form-item-label label {
  font-size: 12px !important;
}
